/* Text Styles */

.u-text-small{
    font-size: 1.3rem;
    line-height: 1.4;
    font-size: 300;
  }
  
  .u-text-light{
    color: var(--color-white);
  }
  
  .u-text-dark{
    color: var(--dark-text);
  }
  
  /* Title */
  
  .u-title{
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .u-title h2{
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--dark-text);
    margin: 1.5rem 0;
  }
  
  .u-title p{
    width: 50%;
    min-width: 40rem;
    margin: 0 auto;
  }