section#subscribe{
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../assets/subscribe-bg.png);
    background-size: cover;
    background-attachment: fixed;
}

.subscribe h2{
    text-align: center;
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 2rem;
    text-transform: uppercase;
    line-height: 1.4;
    color: var(--color-white);
}

.form-control input{
    padding: .8rem 4rem;
    margin: 3rem;
    border-radius: .5rem;
    background: transparent;
    border: 1px solid #777;
    outline: none;
}

form button{
    padding: .5rem;
    font-size: 1.8rem;
    border-radius: .5rem;
    border: 1px solid var(--form-button);
    background: var(--form-button);
    color: var(--color-white);
}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-icon{
    padding: 1rem;
    margin: 2rem;
    width: 4rem;
    height: 4rem;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: orangered;
    cursor: pointer;
    transition: all .3s;
}

.social-icon:hover{
    background: var(--light-blue);
}

@media screen and (max-width:463px) {
    
   

    form button{
        margin-left: 12rem;
    }
}