.features-content{
    display: flex;
    justify-content: center;
}

.features-left{
    flex: 1;
    text-align: center;
}

.features-left img{
    width: 65%;
}

.features-right{
    flex: 1;
}
 
@media screen and (max-width: 600px) {
 
.features-content{
    flex-direction: column;
}
}
