section#download{
    background: linear-gradient(90deg, rgba(45, 165, 255, 1) 45%,
    rgba(1, 223, 245, 1)
    );
    text-align: center;
    color: var(--color-white);
}

.download h2{
    font-size: 3rem;
    font-weight: 200;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 1.5rem 0;
    padding-bottom: 1rem;
    position: relative;
}

.download h2::after{
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0%;
    width: 10%;
    height: .1rem;
    background: var(--color-white);
    transform: translateX(-50%);
}

.download-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
}

.download-icon{
    margin: 1rem 2rem;
    border: 2px solid #fff;
    border-radius: .5rem;
    width: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-size: 1.5rem;
}