 a{
    color: var(--color-white);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 300;
    margin-right: 1rem;
}

a:hover{
    color: orangered;
}

a.btn{
    border: 2px solid var(--color-white);
    border-radius: .5rem;
    cursor: pointer;
    /* margin-left: 1rem; */
    font-weight: 500;
    padding: .5rem .8rem;
}

a.btn-dark{
    border: 2px solid var(--color-white);
    background: transparent;
}

a.btn-orange{
    border: 2px solid transparent;
    background: orangered;
    color: var(--color-white);
}