.question{
    border: 1px solid var(--light-blue);
    border-radius: .5rem;
    width: 90%;
    max-width: 60rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    box-shadow: var(--box-shadow);
}

.question-title{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.question-title h4{
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
}

button.question-icon{
    border: 1px solid var(--color-grey);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}