.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
}

.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}

.logo-text{
    font-size: 4rem;
    font-weight: 300;
    color: var(--color-white);
}

.logo-text span{
    font-weight: 600;
    color: orangered;
}

.nav-links{
    display: flex;
    color: var(--color-white);
    text-decoration: none;
}

.nav-links li{
    list-style: none;
    margin-left: 1rem;
}

.nav-links a{
    color: var(--color-white);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 300;
    margin-right: 1rem;
}

.nav-links a:hover{
    color: orangered;
}

a.btn{
    border: 2px solid var(--color-white);
    border-radius: .5rem;
    cursor: pointer;
    /* margin-left: 1rem; */
    font-weight: 500;
    padding: .5rem .8rem;
}

a.btn-dark{
    border: 2px solid var(--color-white);
    background: transparent;
}

a.btn-orange{
    border: 2px solid transparent;
    background: orangered;
    color: var(--color-white);
}

.menu-icons{
    margin-left: 2rem;

}

.navbar .menu-icons{
    display: none;
}

@media screen and (max-width: 760px) {
.nav-btn{
    display: none;
    }
}

@media screen and (max-width: 600px) {
.nav-btn{
    display: block;
}

.nav-links{
    display: none;
    border: 2px solid var(--color-white);
}

.navbar .menu-icons{
    display: block;
}

.navbar #nav-links-mobile, 
.navbar #nav-links-mobile-hide{
display: block;
position: absolute;
left: 0;
top: 8rem;
width: 100%;
background: var(dark-blue);
border-top: 1px solid var(--color-white);
padding: .5rem;
transition: all 0.3s;
}

.navbar #nav-links-mobile{
    animation: slide-in 0.5s ease-in-out;
}

@keyframes slide-in {
0%{
    transform: translateX(-100%);
} 
100%{
    transform: translateX(0);
}   
}
 
.navbar #nav-links-mobile-hide{
    left: -100%;
    animation: slide-out 0.5s ease-in-out;
}

@keyframes slide-out {
    0%{
        transform: translateX(0);
    } 
    100%{
        transform: translateX(-100%);
    }   
    }

.navbar #nav-links-mobile li, 
.navbar #nav-links-mobile-hide li{
text-align: center;    
padding: 1rem 0;
border-bottom: 1px solid #fff;
margin: 0 3rem;
}

.navbar #nav-links-mobile li:last-child,
.navbar #nav-links-mobile-hide li:last-child{
    border-bottom: none;
}



}